import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { PageState } from '~/store';

@Component
export default class MainLayout extends Vue {
  @State((state: PageState) => state.activeSection) activeSection: string;
  download() {
    window.open(process.env.CHROME_DOWNLOAD_LINK, '_blank');
  }
}
