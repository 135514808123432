export interface PageState {
  activeSection: string;
}

export function state(): PageState {
  return {
    activeSection: 'hai, salam kenal'
  };
}

export const mutations = {
  setSection(state: PageState, section: string) {
    state.activeSection = section;
  }
};
